import { Snackbar } from '@mui/material';
import { Class } from 'generated/graphql';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassVariantsEnum } from 'utils/schedule.utils';
import BothClass from './ScheduleClasses/BothClass';
import VariantClass from './ScheduleClasses/VariantClass';

type ScheduleItemProps = {
  scheduleClass: Class;
};

const ScheduleItem: FC<ScheduleItemProps> = ({ scheduleClass }) => {
  const { t } = useTranslation();
  const [clipboardSnackbar, setClipboardSnackbar] = React.useState(false);

  const onCodeClick = useMemo(
    () => (code: string | undefined | null) => {
      if (code) {
        window.navigator.clipboard.writeText(code);
        setClipboardSnackbar(true);
      }
    },
    [],
  );

  const handleCloseClipboardSnackbar = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setClipboardSnackbar(false);
    },
    [],
  );

  return (
    <>
      {scheduleClass.variant === ClassVariantsEnum.BOTH && (
        <BothClass onCodeClick={onCodeClick} class={scheduleClass} />
      )}
      {scheduleClass.variant === ClassVariantsEnum.ODD && (
        <div className="flex items-center w-full">
          <div className="w-[calc(50%+2px)] border-r-2">
            <VariantClass class={scheduleClass} onCodeClick={onCodeClick} />
          </div>
          <div className="w-1/2" />
        </div>
      )}
      {scheduleClass.variant === ClassVariantsEnum.EVEN && (
        <div className="flex items-center w-full">
          <div className="w-1/2" />
          <div className="w-[calc(50%+1px)] border-l-2">
            <VariantClass class={scheduleClass} onCodeClick={onCodeClick} />
          </div>
        </div>
      )}
      <Snackbar
        open={clipboardSnackbar}
        onClose={handleCloseClipboardSnackbar}
        autoHideDuration={3000}
        message="Код успішно скопійовано"
      />
    </>
  );
};

export default ScheduleItem;
